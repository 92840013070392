<template>
  <div class="bigbox scroll">
    <p class="title">
      监测方案
      <span class="title-test">(数量上限: {{ jc }}条/天,当日已入库: {{ limit }}条)</span>
    </p>
    <ul class="top">
      <li
        v-for="(item, inx) in tag"
        :key="inx"
        @click="jump(item)"
        :class="names == item.name ? 'Selectli' : 'NoSelect'"
      >{{ item.name }}</li>
    </ul>
    <component :jumpData="jumpData" :is="name"></component>
  </div>
</template>

<script>
import territory from "../../views/monitoringScheme/Territory";
import abroad from "../../views/monitoringScheme/abroad";
import Follow from "../../views/monitoringScheme/Follow";

import { monitorNum } from "../../api/monitoringScheme/index";
export default {
  data() {
    return {
      tag: [],
      names: sessionStorage.getItem("names")
        ? sessionStorage.getItem("names")
        : "",
      num: "",
      //   今日已新增
      limit: "",
      //   机构可新增条数
      jc: "",
      name: sessionStorage.getItem("defultName")
        ? sessionStorage.getItem("defultName")
        : "",
      dataList: [],
      jumpData: {}
    };
  },
  components: {
    territory,
    abroad,
    Follow
  },
  watch: {
    "$route.path"(item) {
      this.jumpData = {};
      if (item == "/monitoringScheme/index") {
        this.names = "境内";
        this.name = "territory";
      } else if (item == "/monitoringScheme/index/Territory") {
        this.names = "境内";
        this.name = "territory";
      } else if (item == "/monitoringScheme/index/abroad") {
        this.names = "境外";
        this.name = "abroad";
      } else {
        this.names = "跟评";
        this.name = "Follow";
      }
    }
  },
  created() {
    if (this.$route.query.data) {
      let params = JSON.parse(decodeURIComponent(this.$route.query.data));
      this.jumpData = params;
    } else {
      this.jumpData = {};
    }
    this.monitorNum();
    if (this.$store.state.user.scope[0]) {
      this.tag.push({
        name: "境内",
        names: "territory",
        url: "/monitoringScheme/index/Territory"
      });
      this.dataList.push("territory");
    }
    if (this.$store.state.user.scope[1]) {
      this.tag.push({
        name: "境外",
        names: "abroad",
        url: "/monitoringScheme/index/abroad"
      });
      this.dataList.push("abroad");
    }
    if (this.$store.state.user.scope[2]) {
      this.tag.push({
        name: "跟评",
        names: "Follow",
        url: "/monitoringScheme/index/Follow"
      });
      this.dataList.push("Follow");
    }
    this.name = sessionStorage.getItem("defultName")
      ? sessionStorage.getItem("defultName")
      : this.dataList[0];
    this.names = sessionStorage.getItem("names")
      ? sessionStorage.getItem("names")
      : this.tag[0].name;
    this.num = this.tag[0].url;
    sessionStorage.setItem("names", this.names);
  },
  mounted() {
    window.addEventListener("scroll", this.watchScroll);
  },
  methods: {
    jump(item) {
      // 如果是从首页跳到监测方案，在点击境内境外跟评切换按钮的时候就要清空这个值
      this.jumpData = {};
      sessionStorage.removeItem("setType");
      sessionStorage.removeItem("setTypejn");
      sessionStorage.removeItem("setTypejy");
      sessionStorage.removeItem("defaultId");
      sessionStorage.removeItem("followdefaultId");
      sessionStorage.removeItem("abroaddefaultId");
      sessionStorage.removeItem("abroaddefaultType");
      sessionStorage.removeItem("followdefaultType");

      sessionStorage.setItem("names", item.name);
      sessionStorage.setItem("defultName", item.names);

      this.names = item.name;
      this.num = item.url;
      this.$router.push(item.url);
      this.name = item.names;
    },
    async monitorNum() {
      let res = await monitorNum(this.$store.state.user.organizationId);
      this.limit = res.data.data.limit;
      this.jc = res.data.data.jc;
    },
    //导航栏固定
    watchScroll() {
      if (document.querySelector(".top")) {
        let dom = document.querySelector(".top");
        if (window.pageYOffset > 83) {
          dom.style.transition = "top 0s";
          dom.style.top = window.pageYOffset - 93 + "px";
        } else {
          dom.style.top = 0;
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.bigbox {
  padding: 24px;
  height: 100%;
  .title {
    font-size: 26px;
    font-weight: bold;
    color: #1a1a1a;

    .title-test {
      font-size: 14px;
      color: #666;
      margin-left: 16px;
    }
  }

  .top {
    width: 100%;
    height: 54px;
    background: #ffffff;
    border-radius: 4px;
    margin: 24px 0;
    display: flex;
    padding-left: 48px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    position: relative;
    z-index: 20;
    .Selectli {
      line-height: 54px;
      margin-right: 80px;
      color: #2e59ec;
      border-bottom: 2px solid #2e59ec;
      cursor: pointer;
    }

    .NoSelect {
      line-height: 54px;
      margin-right: 80px;
      cursor: pointer;
    }
  }
}
</style>
